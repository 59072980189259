import React, { useState, useEffect } from "react";
import axios from "axios";

export default function DirectMessageList() {
  const [directMessages, setDirectMessages] = useState([]);
  const [userID, setUserID] = useState("");
  const [users, setUsers] = useState([]);
  const [directMessageObj, setDirectMessageObj] = useState({});
  const [dmList, setDmList] = useState([]);

  useEffect(() => {
    const userID = localStorage.getItem("userID");
    axios
      .get(`http://localhost:4000/user/${userID}`) // assuming props.userId is passed to the component
      .then((response) => {
        setDirectMessages(response.data.directMessages);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`http://localhost:4000/user/showall`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setUserID(userID);
  }, []);

  useEffect(() => {
    Promise.all(
      directMessages.map((directMessage) =>
        axios
          .get(`http://localhost:4000/directmessage/show/${directMessage}`)
          .then((response) => {
            console.log(response.data);
            return response.data;
          })
          .catch((error) => {
            console.log(error);
            return null;
          })
      )
    ).then((results) => {
      setDirectMessageObj(results);
      let dm_list = results.map((directMessage) => {
        let dm_users = directMessage.users;
        let dm_name = "";
        if (dm_users.length === 2) {
          let dm_userID = dm_users[0] === userID ? dm_users[1] : dm_users[0];
          let dm_user = users.find((user) => user._id === dm_userID);
          dm_name = dm_user.name;
        } else if (dm_users.length > 2) {
          dm_name = directMessage.name;
        } else {
          console.log("Error: Direct Message has no users");
        }
        return (<div id={directMessage._id} className="dm_name" onClick={(event) => handleDMNameClick(event)} key={directMessage._id}>
          {dm_name}
          </div>);
      });
    
      setDmList((prevDmList) => dm_list);

    }).catch((error) => {
      console.log(error);
    }
    );

  }, [directMessages]);

  function handleDMNameClick(event) {
    console.log("DM Name Clicked");
    window.location.replace("/directmessagechat/" + event.target.id);
  }

  

  return (
    <div>
      <h1>Direct Messages</h1>
      {dmList}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import bcrypt from "bcryptjs";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      // Retrieve the salt for the given username
      const user = await axios.get(`${BACKEND_URL}/user/username/${username}`);
      let savedHashedPassword = user.data.password;
      let userID = user.data._id;

      // Compare the generated hash with the saved hash
      const isMatch = await bcrypt.compare(password, savedHashedPassword);

      if (!isMatch) {
        document.getElementById("comments").innerHTML = "Invalid credentials";
        document.getElementById("comments").className =
          "alert alert-danger alert-dismissible fade show";
        throw Error("Invalid credentials");
      } else {
        document.getElementById("comments").innerHTML = "Success";
        document.getElementById("comments").className =
          "alert alert-success alert-dismissible fade show";

        localStorage.setItem("userID", userID);

        window.location.replace("/home");
      }
    } catch (error) {
      console.log(error);
      document.getElementById("comments").innerHTML = "Error";
      document.getElementById("comments").className =
        "alert alert-danger alert-dismissible fade show";
    }
  };

  return (
    <div>
      <Navbar />

      <div className="container">
        <br />
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Username: </label>
            <input
              type="text"
              className="form-control"
              required={true}
              value={username}
              onChange={onChangeUsername}
            />
          </div>
          <br />
          <div className="form-group">
            <label>Password: </label>
            <input
              type="password"
              className="form-control"
              required={true}
              value={password}
              onChange={onChangePassword}
            />
          </div>

          <br />
          <div className="form-group">
            <input type="submit" value="Login" className="btn btn-primary" />
          </div>
          <div id="comments"></div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Communities from "./Communities";
import Navbar from "./Navbar";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const HUGGING_FACE_API_KEY = process.env.REACT_APP_HUGGING_FACE_API_KEY;

export default function HomePage() {
  const [userID, setUserID] = useState("0");
  const [activity, setActivity] = useState("");
  const [communities, setCommunities] = useState([]);

  useEffect(() => {
    let userID = localStorage.getItem("userID");
    setUserID(userID);
  }, []);

  async function query(data) {
    const response = await fetch(
      "https://api-inference.huggingface.co/models/sentence-transformers/all-MiniLM-L6-v2",
      {
        headers: {
          Authorization: `Bearer ${HUGGING_FACE_API_KEY}`,
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    return result;
  }

  async function getSimilarityScore(target, sources) {
    const scores = await query({
      inputs: {
        source_sentence: target,
        sentences: sources,
      },
    });
    return scores;
  }

  function sortCommunities(communities, scores) {
    let sortedCommunities = [];
    for (let i = 0; i < scores.length; i++) {
      let max = 0;
      let maxIndex = 0;
      for (let j = 0; j < scores.length; j++) {
        if (scores[j] > max) {
          max = scores[j];
          maxIndex = j;
        }
      }
      sortedCommunities.push(communities[maxIndex]);
      scores[maxIndex] = -1;
    }
    return sortedCommunities;
  }

  async function showCommunities() {
    let communities = await axios.get(`${BACKEND_URL}/community/showall`);
    communities = communities.data;

    let sources = communities.map((community) => community.name);

    let target = activity;

    let scores = await getSimilarityScore(target, sources);

    communities = sortCommunities(communities, scores);
    setCommunities(communities);
  }

  // use effect to execute on changing the state activity
  useEffect(() => {
    showCommunities();
  }, [activity]);

  function onCreateCommunity(e) {
    let newCommunity = {
      name: activity,
      description: "This is a community for " + activity,
      users: [userID],
    };
    axios
      .post(`${BACKEND_URL}/community/addCommunity`, newCommunity)
      .then((res) => {
        // add the community to the user
        let communityID = res.data.id;
        let newUser = {
          _id: userID,
          community: [communityID],
        };
        axios
          .post(`${BACKEND_URL}/user/addCommunity`, newUser)
          .then((res) => {
            window.location.replace(`/communitychat/${communityID}`);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function onChangeActivity(e) {
    e.preventDefault();
    const newUser = {
      _id: userID,
      activity: activity,
    };
    axios
      .post(`${BACKEND_URL}/user/update`, newUser)
      .then((res) => {
        //success
      })
      .catch((err) => {
        console.error(err);
      });
    setActivity(e.target.value);
  }

  return (
    // make a text box with label "Tell what you are doing"
    // make a button with label "Enter"
    <div>
      <Navbar />
      <br />
      <div className="buttons text-center container">
        <div className="activity-box-wrapper">
          <h1>What are you doing?</h1>
          <input
            className="form-control"
            type="text"
            id="activity"
            name="activity"
            value={activity}
            onChange={onChangeActivity}
          />
        </div>
        <br />
        <button className="create-community" onClick={onCreateCommunity}>
          Create Community
        </button>
        <br />
        <Communities communityList={communities} />
        <div id="communities"></div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
// import { BACKEND_URL } from "./.env";

import RegisterPage from "./components/RegisterPage";
import LandingPage from "./components/LandingPage";
import LoginPage from "./components/LoginPage";
import ProfilePage from "./components/ProfilePage";
import HomePage from "./components/HomePage";
import EditProfilePage from "./components/EditProfilePage";
import MeetPage from "./components/MeetPage";
import DirectMessageList from "./components/DirectMessageList";
import CommunityChat from "./components/CommunityChat";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function App() {
  const [userID, setUserID] = useState("");
  const [eventTriggered, setEventTriggered] = useState(false);

  function updateLastActive() {
    axios
      .get(`${BACKEND_URL}/user/${userID}/updateLastActivity`)
      .then((res) => {
        setEventTriggered(false); // Reset the state after successful API call
      })
      .catch((err) => console.log(err));
  }
  // Function to trigger the event
  function triggerEvent() {
    setEventTriggered(true);
  }

  useEffect(() => {
    setUserID(localStorage.getItem("userID"));

    const interval = setInterval(() => {
      if (eventTriggered) {
        updateLastActive();
      }
    }, 300000); // 5 minutes in milliseconds

    document.addEventListener("mousemove", triggerEvent);
    document.addEventListener("keypress", triggerEvent);

    return () => {
      document.removeEventListener("mousemove", triggerEvent);
      document.removeEventListener("keypress", triggerEvent);
      clearInterval(interval);
    };
  }, [eventTriggered]);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/editprofile" element={<EditProfilePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/meet" element={<MeetPage />} />
          <Route path="/directmessagelist" element={<DirectMessageList />} />
          <Route
            path="/communitychat/:communityID"
            element={<CommunityChat />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

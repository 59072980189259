import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function CommunityChat() {
  const { id } = useParams();
  const messageListRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const [userID, setUserID] = useState("");
  const [communityID, setCommunityID] = useState("");
  const [pageCount, setPageCount] = useState(1);
  // const [friendDetails, setFriendDetails] = useState({
  //   show: false,
  //   x: 0,
  //   y: 0,
  //   message: "",
  // });

  useEffect(() => {
    // get the messages from the server
    axios
      .get(`http://localhost:4000/community/${communityID}/messages/1`)
      .then((res) => {
        // res.data has timestamp, user, message, _id and file attributes
        setMessages(res.data);
      })
      .catch((err) => console.log(err));
  }, [communityID]);

  useEffect(() => {
    let userID = localStorage.getItem("userID");
    setUserID(userID);
    setCommunityID(id);
  }, []);

  const fetchNextMessages = () => {
    axios
      .get(`http://localhost:4000/community/${id}/messages/${pageCount + 1}`)
      .then((res) => {
        // res.data has timestamp, user, message, _id, and file attributes
        // Prepend the new messages to the existing messages
        setMessages((prevMessages) => [...res.data, ...prevMessages]);
        console.log(pageCount + 1);
        setPageCount((prevPageCount) => prevPageCount + 1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const handleScroll = () => {
      console.log("scrolling");
      if (messageListRef.current.scrollTop === 0) {
        fetchNextMessages();
      }
    };

    if (messageListRef.current) {
      messageListRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (messageListRef.current) {
        messageListRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetchNextMessages]);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleFileChange = (e) => {
    var file_input = document.getElementById("upload-file");
    const selectedFile = file_input.files[0];
    return selectedFile;
  };

  const clearUploadedFile = () => {
    var file_input = document.getElementById("upload-file");
    file_input.value = null;
  };

  const handleSend = (e) => {
    e.preventDefault();
    var uploaded_file = handleFileChange();

    // Create a new FormData object
    const formData = new FormData();
    formData.append("userID", userID);
    formData.append("message", text);
    formData.append("file", uploaded_file);
    console.log(formData);

    // Send the request with the FormData
    axios
      .put(
        `http://localhost:4000/community/${communityID}/addMessage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
          },
        }
      )
      .then((res) => {
        setText("");
        clearUploadedFile();

        // update the messages state
        // get the messages from the server
        axios
          .get(`http://localhost:4000/community/${communityID}/messages/1`)
          .then((res) => {
            // res.data has timestamp, user, message, _id, and file attributes
            setMessages(res.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  // function displayFriendDetails(event, message) {
  //   setFriendDetails({
  //     show: true,
  //     x: event.clientX,
  //     y: event.clientY,
  //     message: message,
  //   });
  // }

  // function hideFriendDetails() {
  //   setFriendDetails((prevFriendDetails) => ({
  //     ...prevFriendDetails,
  //     show: false,
  //   }));
  // }

  // function retainFriendDetails(event) {
  //   // make the show attribute true and don't change the x and y coordinates
  //   setFriendDetails((prevFriendDetails) => ({
  //     ...prevFriendDetails,
  //     show: true,
  //   }));
  // }

  // function isFriendHovered(user) {
  //   return axios
  //     .get(`http://localhost:4000/user/${userID}`)
  //     .then((res) => {
  //       const friends = res.data.friends;
  //       const isFriend = friends.find((friend) => friend._id === user._id);
  //       return isFriend;
  //     })
  //     .catch((err) => console.log(err));
  // }

  // function addFriend(user) {
  //   // check if the user is not himself
  //   if (user._id !== userID) {
  //     // check if the user is already a friend

  //     axios
  //       .post(`http://localhost:4000/user/sendFriendRequest`, {
  //         userID: userID,
  //         friendID: user._id,
  //       })
  //       .then((res) => {
  //         console.log(res.data);
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }

  // function FriendDetails() {
  //   const [isFriend, setIsFriend] = useState(false);

  //   useEffect(() => {
  //     isFriendHovered(friendDetails.message.user)
  //       .then((res) => {
  //         setIsFriend(res);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setIsFriend(false);
  //       });
  //   }, [friendDetails]);

  //   if (!friendDetails.show) {
  //     return null;
  //   }

  //   console.log(isFriend);

  //   const friendDetailsComponent = isFriend ? (
  //     <div
  //       className="friend-details"
  //       onMouseLeave={hideFriendDetails}
  //       onMouseEnter={retainFriendDetails}
  //       style={{ left: friendDetails.x, top: friendDetails.y }}
  //     >
  //       <p>
  //         <b>{friendDetails.message.user.username}</b>
  //         &nbsp;
  //         <button className="friend-dm-button">Direct Message</button>
  //       </p>
  //     </div>
  //   ) : (
  //     <div
  //       className="friend-details"
  //       onMouseLeave={hideFriendDetails}
  //       onMouseEnter={retainFriendDetails}
  //       style={{ left: friendDetails.x, top: friendDetails.y }}
  //     >
  //       <p>
  //         <b>{friendDetails.message.user.username}</b>
  //         &nbsp;
  //         <button
  //           onClick={() => addFriend(friendDetails.message.user)}
  //           className="addfriend-button"
  //         >
  //           Add Friend
  //         </button>
  //       </p>
  //     </div>
  //   );

  //   return friendDetailsComponent;
  // }

  const handleDownload = (messageFileID) => {
    // Get the message file from the server
    axios
      .get(`http://localhost:4000/file/${messageFileID}`)
      .then((res) => {
        // res.data has contentType and data attributes
        console.log(res.data);
        const messageFile = res.data;
        const type = messageFile.contentType;
        const data = new Uint8Array(messageFile.data.data);
        const blob = new Blob([data], { type });

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and set its attributes
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `file.${getExtensionFromContentType(type)}`
        );

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up the temporary URL and remove the link from the body
        URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
      })
      .catch((err) => console.log(err));
  };

  // Helper function to extract file extension from content type
  const getExtensionFromContentType = (contentType) => {
    switch (contentType) {
      case "application/pdf":
        return "pdf";
      case "image/jpeg":
        return "jpg";
      case "image/png":
        return "png";
      case "image/gif":
        return "gif";
      case "video/mp4":
        return "mp4";
      case "video/mpeg":
        return "mpeg";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx";
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xlsx";
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return "pptx";
      case "application/vnd.ms-powerpoint":
        return "ppt";
      case "application/vnd.ms-excel":
        return "xls";
      case "application/msword":
        return "doc";
      default:
        return "txt";
    }
  };

  console.log(messages);

  return (
    <div>
      {/* <DirectMessage /> */}
      <div className="meet-page container" ref={messageListRef}>
        <div className="message-list">
          {messages.map((message, index) => (
            <div className="message" key={index}>
              <p>
                <b>
                  <span className="message-username">{message.username}</span>
                </b>
                <br />
                <br />
                {message.message}
                <br />
                {message.fileID && (
                  <button onClick={() => handleDownload(message.fileID)}>
                    Download File
                  </button>
                )}
                <br />
                <span className="message-timestamp">
                  <small>{new Date(message.timestamp).toLocaleString()}</small>
                </span>
              </p>
            </div>
          ))}
        </div>
        <div className="chat-box">
          <textarea
            value={text}
            onChange={handleTextChange}
            placeholder="Type your message here"
          ></textarea>
          <div className="button-container">
            <input type="file" id="upload-file" onClick={handleFileChange} />

            <button onClick={handleSend}>Send</button>
          </div>
        </div>
        {/* <FriendDetails /> */}
      </div>
    </div>
  );
}

export default CommunityChat;

import axios from "axios";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Navbar = () => {
  const [communityID, setCommunityID] = useState("");
  const [communityName, setCommunityName] = useState("");
  const [userID, setUserID] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    let userID = localStorage.getItem("userID");
    setUserID(userID);
    axios
      .get(`${BACKEND_URL}/user/${userID}`)
      .then((res) => {
        let user = res.data;
        setCommunityID(user.community);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (communityID) {
      axios
        .get(`${BACKEND_URL}/community/${communityID}`)
        .then((res) => {
          let community = res.data;
          setCommunityName(community.name);
        })
        .catch((err) => console.log(err));
    }
  }, [communityID]);

  const logout = () => {
    localStorage.removeItem("userID");
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav>
      <div className="nav-bar">
        <div className="nav-brand">
          <Link to="/">Collaborate</Link>
        </div>
        <div className="nav-buttons-list">
          {communityID && userID && (
            <Link
              to={`/communitychat/${communityID}`}
              className="community-name-button"
            >
              {communityName}
            </Link>
          )}
          <div className="menu-button" onClick={toggleMenu}>
            <div className={`menu-icon ${menuOpen ? "open" : ""}`}></div>
          </div>
          {userID && (
            <div className={`nav-buttons ${menuOpen ? "open" : ""}`}>
              <Link to="/home">Communities</Link>
              <Link to="/profile">Profile</Link>
              <Link to="/" onClick={logout}>
                Log Out
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import Navbar from "./Navbar";

export default function LandingPage() {
  return (
    <div className="landing-container">
      <Navbar />
      <div className="landing-button-container">
        <Link to="/login">
          <button className="btn btn-primary">Log In</button>
        </Link>
        <Link to="/register">
          <button className="btn btn-primary" id="reg_btn">
            <span>Register</span>
          </button>
        </Link>
      </div>
      <div className="app-info">
        <h2>Welcome to Collaborate</h2>
        <p>
          Bored of working alone? Collaborate with others doing the same
          activity as you!
        </p>
      </div>
    </div>
  );
}

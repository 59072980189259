import React, { useState, useEffect, Component } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import bcrypt from "bcryptjs";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function EditProfilePage() {
  const [userID, setUserID] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    let userID = localStorage.getItem("userID");
    if (!userID || userID == 0 || userID == "" || userID == "0") setUserID("0");
    else {
      setUserID(userID);
    }
  });

  const onChangeUsername = (event) => {
    setUsername(event.target.value);
  };
  const onChangePhone = (event) => {
    setPhone(event.target.value);
  };

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const onChangeName = (event) => {
    setName(event.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const saltRounds = 10;
    const salt = bcrypt.genSaltSync(saltRounds);
    const hashedPassword = bcrypt.hashSync(password, salt);

    const newUser = {
      _id: userID,
      phone: phone,
      name: name,
      username: username,
      email: email,
      password: hashedPassword,
    };
    axios
      .post(`${BACKEND_URL}/user/update`, newUser)
      .then((res) => {
        document.getElementById("comments").innerHTML = "Success";
        document.getElementById("comments").className =
          "alert alert-success alert-dismissible fade show";
        window.location.replace("/profile");
      })
      .catch((err) => {
        document.getElementById("comments").innerHTML = "Error";
        document.getElementById("comments").className =
          "alert alert-danger alert-dismissible fade show";
      });
  };
  return (
    <div>
      <Navbar />
      <br />
      <div className="container">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Name: </label>
            <input
              type="text"
              className="form-control"
              required="true"
              value={name}
              onChange={onChangeName}
            />
          </div>
          <br />
          <div className="form-group">
            <label>Username: </label>
            <input
              type="text"
              className="form-control"
              required="true"
              value={username}
              onChange={onChangeUsername}
            />
          </div>
          <br />
          <div className="form-group">
            <label>Password: </label>
            <input
              type="password"
              className="form-control"
              required="true"
              value={password}
              onChange={onChangePassword}
            />
          </div>
          <br />
          <div className="form-group">
            <label>Email: </label>
            <input
              type="email"
              className="form-control"
              required="true"
              value={email}
              onChange={onChangeEmail}
            />
          </div>
          <br />
          <div className="form-group">
            <label>Contact Number: </label>
            <input
              type="text"
              className="form-control"
              required="true"
              value={phone}
              onChange={onChangePhone}
            />
          </div>

          <br />
          <div className="form-group">
            <input type="submit" value="Update" className="profile-button" />
          </div>
          <div id="comments"></div>
        </form>
      </div>
    </div>
  );
}

export default EditProfilePage;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Navbar from "./Navbar";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function CommunityChat() {
  const { communityID } = useParams();
  const messageListRef = useRef(null);
  const fetchMessagesIntervalRef = useRef(null);
  const pageCountRef = useRef(1); // Use a ref for pageCount

  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const [userID, setUserID] = useState("");

  useEffect(() => {
    setUserID(localStorage.getItem("userID"));
    fetchMessagesIntervalRef.current = setInterval(handleScroll, 1000);

    // on unmounting
    return () => {
      clearInterval(fetchMessagesIntervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (messageListRef.current) {
      if (pageCountRef.current === 1) {
        messageListRef.current.scrollTop =
          messageListRef.current.scrollHeight -
          messageListRef.current.clientHeight;
      } else if (pageCountRef.current === messages.length / 10) {
        messageListRef.current.scrollTop = 1000;
      }
    }
  }, [messages]);

  function fetchNextMessages() {
    axios
      .get(
        `${BACKEND_URL}/community/${communityID}/messages/${
          pageCountRef.current + 1
        }`
      )
      .then((res) => {
        setMessages((prevMessages) => [...res.data, ...prevMessages]);
      })
      .catch((err) => console.log(err));
  }

  function fetchMessages() {
    axios
      .get(`${BACKEND_URL}/community/${communityID}/messages/1`)
      .then((res) => {
        setMessages(res.data);
      })
      .catch((err) => console.log(err));
  }

  function handleScroll() {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      console.log(scrollTop, scrollHeight, clientHeight, pageCountRef.current);

      const hasScrollBar = scrollHeight > clientHeight;

      if (!hasScrollBar) {
        console.log("No scroll bar initially");
        fetchMessages();
        pageCountRef.current = 1;
      } else if (scrollTop + clientHeight >= scrollHeight - 1) {
        console.log("Reached bottom");
        fetchMessages();
        pageCountRef.current = 1;
      } else if (scrollTop === 0) {
        console.log("Reached top");
        fetchNextMessages();
        pageCountRef.current += 1; // Update the pageCount using the ref
      }
    }
  }

  const handleTextChange = (e) => {
    setText(e.target.value);
    // get the value of the last character
    const lastChar = e.target.value[e.target.value.length - 1];
    if (lastChar === "\n") {
      // click the send button
      document.getElementById("send-button").click();
    }
  };

  const handleFileChange = (e) => {
    var file_input = document.getElementById("upload-file");
    const selectedFile = file_input.files[0];
    return selectedFile;
  };

  const clearUploadedFile = () => {
    var file_input = document.getElementById("upload-file");
    file_input.value = null;
  };

  const handleSend = (e) => {
    e.preventDefault();
    var uploaded_file = handleFileChange();

    // Create a new FormData object
    const formData = new FormData();
    formData.append("userID", userID);
    formData.append("message", text);
    formData.append("file", uploaded_file);

    // Send the request with the FormData
    axios
      .put(`${BACKEND_URL}/community/${communityID}/addMessage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
        },
      })
      .then((res) => {
        setText("");
        clearUploadedFile();
        fetchMessages();
      })
      .catch((err) => console.log(err));
  };

  const handleDownload = (messageFileID) => {
    // Get the message file from the server
    axios
      .get(`${BACKEND_URL}/file/${messageFileID}`)
      .then((res) => {
        // res.data has contentType and data attributes
        const messageFile = res.data;
        const type = messageFile.contentType;
        const data = new Uint8Array(messageFile.data.data);
        const blob = new Blob([data], { type });

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and set its attributes
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `file.${getExtensionFromContentType(type)}`
        );

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up the temporary URL and remove the link from the body
        URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
      })
      .catch((err) => console.log(err));
  };

  // Helper function to extract file extension from content type
  const getExtensionFromContentType = (contentType) => {
    switch (contentType) {
      case "application/pdf":
        return "pdf";
      case "image/jpeg":
        return "jpg";
      case "image/png":
        return "png";
      case "image/gif":
        return "gif";
      case "video/mp4":
        return "mp4";
      case "video/mpeg":
        return "mpeg";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx";
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xlsx";
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return "pptx";
      case "application/vnd.ms-powerpoint":
        return "ppt";
      case "application/vnd.ms-excel":
        return "xls";
      case "application/msword":
        return "doc";
      default:
        return "txt";
    }
  };

  return (
    <div>
      <Navbar />
      <div
        id="message-list-box"
        className="meet-page container"
        ref={messageListRef}
      >
        <div className="message-list">
          {messages.map((message, index) => (
            <div className="message" key={index}>
              <p>
                <b>
                  <span className="message-username">{message.username}</span>
                </b>
                <br />
                <br />
                {message.message}
                {message.fileID && (
                  <span>
                    <br />
                    <button
                      id="download-attachment"
                      onClick={() => handleDownload(message.fileID)}
                    >
                      Download Attachment
                    </button>
                  </span>
                )}
                <br />
                <span className="message-timestamp">
                  <small>{new Date(message.timestamp).toLocaleString()}</small>
                </span>
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="chat-box container">
        <textarea
          value={text}
          onChange={handleTextChange}
          placeholder="Type your message here"
        ></textarea>
        <div className="button-container">
          <input type="file" id="upload-file" onClick={handleFileChange} />

          <button id="send-button" onClick={handleSend}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default CommunityChat;

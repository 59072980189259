import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Navbar from "./Navbar";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function ProfilePage() {
  const [user, setUser] = useState("");

  const handleClick = () => {
    window.location.replace("editprofile");
  };
  let userID = "";
  useEffect(() => {
    userID = localStorage.getItem("userID");
    axios.get(`${BACKEND_URL}/user/` + userID).then((response) => {
      setUser(response.data);
    });
  }, []);

  return (
    <div>
      <Navbar />
      <br />
      <div className="container">
        <h2>YOUR PROFILE DETAILS</h2>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <th>Name </th>
              <td>{user.name}</td>
            </tr>
            <tr>
              <th> Username </th>
              <td>{user.username}</td>
            </tr>
            <tr>
              <th> Email </th>
              <td>{user.email}</td>
            </tr>
            <tr>
              <th> Contact Number </th>
              <td>{user.phone}</td>
            </tr>
          </tbody>
        </Table>
        <div>
          <button
            onClick={handleClick}
            value={handleClick}
            type="button"
            className="profile-button"
          >
            Edit Profile/Change Password
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;

import React from "react";
import CommunityCard from "./CommunityCard";

export default function Communities(props) {
    let CommunityCardList = props.communityList.map((community) => <CommunityCard key={props.communityList._id} community={community} />)

    return (
    <div>
    <div className="CommunityCards">
        {CommunityCardList}
    </div>
    </div>
    )
}

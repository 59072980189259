import React, { useState, useEffect } from "react";
import axios from "axios";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default function CommunityCard(props) {
  const [userID, setUserID] = useState("0");

  useEffect(() => {
    let userID = localStorage.getItem("userID");
    setUserID(userID);
  }, []);

  const joinCommunity = () => {
    axios
      .put(`${BACKEND_URL}/community/addUser`, {
        userIDs: [userID],
        communityID: props.community._id,
      })
      .then((res) => {
        // add the community to the user
        let newUser = {
          _id: userID,
          community: [props.community._id],
        };
        axios
          .post(`${BACKEND_URL}/user/addCommunity`, newUser)
          .then((res) => {
            window.location.replace("/communitychat/" + props.community._id);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="Communitycard">
      <h3>{props.community.name}</h3>
      <p>{props.community.description}</p>
      <p>Active users: {props.community.users.length}</p>
      <button className="btn btn-primary" onClick={joinCommunity}>
        Join
      </button>
    </div>
  );
}
